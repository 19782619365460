/* Index/landing page */
.index {
    text-align: center;

    &__welcome {
        &__text {
            margin: 1.5rem auto;
            max-width: 360px;
        }
    }

    &__login {
        margin-top: 2rem;

        em {
            margin-bottom: 0.5rem;
            display: inline-block;
        }
    }

    .login-form {
        margin: 0 auto;
    }
}

/* Home page */
.membership-duration-hint {
    font-style: italic;
    text-align: center;
    margin-top: 0.8rem;
}


/* Membership page */
.membership-item {
    margin-bottom: 1rem;
}

#user-form {
    margin: 0 auto;
    max-width: 350px;
    text-align:left;
}

/* Login page */
.page-login {
    text-align: center;
    .login-form {
        margin: 0 auto;
    }
}

/* User register */
.user-register {
    h4 {
        text-align: center;
    }
}

@include media-breakpoint-down(sm) {
    #orgunit-name {
        font-size: 1.5em;
    }
}

/* Stats */
.page-stats {
    .table-wrap {
        max-height: 80vh;
        overflow-y: auto;
    }
}
