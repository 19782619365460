body {
    /* ... */
}

svg {
    vertical-align: baseline;
}

.messages {
    margin: 0 auto;
    max-width: 650px;

    &:empty {
        display: none;
    }
}

.alert-error {
    @extend .alert-danger;
}

.big-number {
    font-weight: bold;
    font-size: 1.5em;
}

/* do not underline links unless hovered over */
a:not([class*="btn"]) {
    text-decoration: none;
}
a:not([class*="btn"]):hover {
    text-decoration: underline;
}