/* Forms */
.login-form {
    text-align: left;
    max-width: 350px;

    .submit-button-wrap {
        margin-top: 0.8rem;

        @include media-breakpoint-up(sm) {
            text-align: right;
        }
    }
    [type=submit] {
        display: block;
        width: 100%;
        @include media-breakpoint-up(sm) {
            text-align: right;
            display: inline-block;
            width: auto;
        }
    }
    .form-control-label {
        display: none;
    }
}

/* Forms: Disable stepper */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}
button[type=submit] {
    cursor: pointer;
}

.form-page {
    text-align: left;
    max-width: 650px;
}

.membershiptype-radio-buttons {
	margin: 10px auto 5px;
	display: flex;
	gap: 1rem;
}
