.nav-wrap {
    background-color: $color-orange;

    .navbar {
        max-width: $grid-max-width;
        margin: 0 auto;
    }

    .navbar-brand {
        width: 6.5em;
        margin-top: 0.2em;

        svg {
            height: 1.2em;
            width: auto;
        }

        svg path {
            fill: white;
        }
    }
}

#main-navigation {
    padding: 0;

    a.nav-link {
        color: white;
        transition: all 0.14s ease-in-out;

        &:hover,
        &.active {
            background-color: rgba(white, 0.15);
        }
    }
}

.user-menu {
    .dropdown-menu {
        right: 0;
        left: auto;
    }
    .dropdown-item {
        padding: 8px 16px;
        text-align: right;

        .lnr {
            margin-right: 4px;
        }
    }
}

.language-select {
    &.hidden {
        display: none;
    }
}

.navbar-toggler {
    svg {
        top: 1px;
        width: 24px;
        height: 24px;
    }
}

.nav-link,
.navbar-toggler {
    svg path {
        /*fill: rgba(0, 0, 0, 0.5);*/
        fill: white;
    }
    &:hover svg path,
    &.active svg path {
        /*fill: rgba(0, 0, 0, 1);*/
        fill: white;
    }
}
