.flatpage {
    p,h1,h2,h3,h4,h5,h6,ul {
        max-width: 700px;
        margin: 0 auto;
    }
    h1,h2,h3,h4,h5,h6 {
        margin-bottom: 0.15em;
    }

    p,ul {
        margin-bottom: 1em;
    }
    img {
        max-width: 100%;
    }
}
